import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg';
import {PageInfo} from '../../generated';

type PaginatorType = {
  count: number;
  pageInfo?: PageInfo;
  loadMore: (next: boolean) => Promise<void>;
};
const Paginator = ({count, pageInfo, loadMore}: PaginatorType) => {
  return (
    <div className="flex justify-between pb-8 items-center">
      <p className="font-semibold">{pageInfo?.entries} entries</p>
      <div className="flex items-center gap-6">
        <div
          className={`flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-faintGrey ${
            pageInfo?.hasPrevPage ? 'cursor-pointer' : 'cursor-not-allowed'
          }`}
          onClick={() => pageInfo?.hasPrevPage && loadMore(false)}>
          {pageInfo?.hasPrevPage ? (
            <img
              className="h-[14px] w-[14px] rotate-180"
              src={ArrowRightIcon}
            />
          ) : (
            <img className="h-[14px] w-[14px]" src={ArrowLeftIcon} />
          )}
        </div>

        <div className="flex  h-[40px] min-w-[40px] items-center justify-center rounded-lg border border-greyish px-3 py-1 text-center font-[16px]">
          {count || 1}
        </div>
        <div
          className={`flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-faintGrey ${
            pageInfo?.hasNextPage ? 'cursor-pointer' : 'cursor-not-allowed'
          }`}
          onClick={() => pageInfo?.hasNextPage && loadMore(true)}>
          {pageInfo?.hasNextPage ? (
            <img className="h-[14px] w-[14px]" src={ArrowRightIcon} />
          ) : (
            <img className="h-[14px] w-[14px] rotate-180" src={ArrowLeftIcon} />
          )}
        </div>
      </div>
      <p className="font-semibold">{pageInfo?.pages} pages</p>
    </div>
  );
};

export default Paginator;
