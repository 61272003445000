import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  TimeInHm: { input: string; output: string; }
  Upload: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  buildingName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location: Location;
};

export type AddressInput = {
  buildingName?: InputMaybe<Scalars['String']['input']>;
  coordinates: GeoCoordinatesInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  location: Scalars['String']['input'];
};

export type Bank = {
  __typename?: 'Bank';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountHolder: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  bank: Bank;
  id: Scalars['ID']['output'];
};

export type Business = {
  __typename?: 'Business';
  address: Address;
  bankAccount: BankAccount;
  category: BusinessCategory;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Users associated with the business. Only has the user that submitted the form for now */
  members: Array<User>;
  name: Scalars['String']['output'];
  owner: BusinessOwner;
  phoneNumber: PhoneNumber;
  socialMedia: BusinessSocialMedia;
};

export type BusinessCategory = {
  __typename?: 'BusinessCategory';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BusinessOnboarding = {
  __typename?: 'BusinessOnboarding';
  address?: Maybe<Address>;
  bankAccountBank?: Maybe<Bank>;
  bankAccountHolder?: Maybe<Scalars['String']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  category?: Maybe<BusinessCategory>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  ownerAddress?: Maybe<Address>;
  ownerFirstName?: Maybe<Scalars['String']['output']>;
  ownerIdPhotos?: Maybe<Array<Media>>;
  ownerIdType?: Maybe<VerificationIdType>;
  ownerLastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<PhoneNumber>;
  socialMedia?: Maybe<BusinessSocialMedia>;
  user: User;
};

export type BusinessOnboardingBankAccountInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['ID']['input']>;
};

export type BusinessOnboardingInput = {
  address?: InputMaybe<AddressInput>;
  bankAccount?: InputMaybe<BusinessOnboardingBankAccountInput>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<BusinessOnboardingOwnerInput>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  socialMedia?: InputMaybe<BusinessOnboardingSocialMediaInput>;
};

export type BusinessOnboardingOwnerInput = {
  address?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  idPhotos?: InputMaybe<Array<Scalars['ID']['input']>>;
  idType?: InputMaybe<VerificationIdType>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessOnboardingSocialMediaInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  snapchat?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessOwner = {
  __typename?: 'BusinessOwner';
  address: Address;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idPhotos: Array<Media>;
  idType: VerificationIdType;
  lastName: Scalars['String']['output'];
};

export type BusinessSocialMedia = {
  __typename?: 'BusinessSocialMedia';
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  snapchat?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type Card = {
  __typename?: 'Card';
  flowData?: Maybe<CardFlowData>;
  issuer?: Maybe<CardIssuer>;
  /** card no format eg ********1111 */
  number?: Maybe<Scalars['String']['output']>;
};

export type CardFlowData = {
  __typename?: 'CardFlowData';
  actionUrl?: Maybe<Scalars['String']['output']>;
  referenceId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type CardFlowDataInput = {
  actionUrl?: InputMaybe<Scalars['String']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CardInput = {
  /** card cvv format eg 123 */
  cvv?: InputMaybe<Scalars['String']['input']>;
  /** expiryMonth format eg 01 - for January to 12 - for December */
  expiryMonth?: InputMaybe<Scalars['String']['input']>;
  /** expiryYear format eg 2025 */
  expiryYear?: InputMaybe<Scalars['String']['input']>;
  flowData?: InputMaybe<CardFlowDataInput>;
  issuer?: InputMaybe<CardIssuer>;
  /** card no format eg 4111111111111111 */
  number?: InputMaybe<Scalars['String']['input']>;
};

/** CardIssuer to identity the card issuer */
export enum CardIssuer {
  Mastercard = 'MASTERCARD',
  Visa = 'VISA'
}

export enum CurrencyCode {
  /** Kenyan Shilling (KES). */
  Kes = 'KES'
}

export type CursorInput = {
  /** Going forward */
  after?: InputMaybe<Scalars['ID']['input']>;
  /** Going backwards */
  before?: InputMaybe<Scalars['ID']['input']>;
  /** Most be positive integer */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Most be positive integer */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Error = {
  type: ErrorTypes;
};

export enum ErrorTypes {
  AccountLocked = 'ACCOUNT_LOCKED',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND',
  NotRegistered = 'NOT_REGISTERED',
  /** Protected field: Only Admins and owners can access the field */
  ProtectedField = 'PROTECTED_FIELD',
  /** Service error, please retry */
  ServerError = 'SERVER_ERROR',
  TokenExpired = 'TOKEN_EXPIRED',
  TokenInvalid = 'TOKEN_INVALID',
  ValidationError = 'VALIDATION_ERROR'
}

export type GeoCoordinates = {
  __typename?: 'GeoCoordinates';
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
};

export type GeoCoordinatesInput = {
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
};

export type Image = {
  __typename?: 'Image';
  id?: Maybe<Scalars['ID']['output']>;
  large?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  small?: Maybe<Scalars['String']['output']>;
};

export type Location = {
  __typename?: 'Location';
  coordinates: GeoCoordinates;
  formattedAddress: Scalars['String']['output'];
};

export type Login = {
  __typename?: 'Login';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  user: User;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LogoutInput = {
  refreshToken: Scalars['String']['input'];
};

export type Media = {
  __typename?: 'Media';
  contentType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type Money = {
  __typename?: 'Money';
  amountInCents: Scalars['Int']['output'];
  currencyCode: CurrencyCode;
};

export type MoneyInput = {
  amountInCents: Scalars['Int']['input'];
  currencyCode: CurrencyCode;
};

export type Order = {
  __typename?: 'Order';
  card?: Maybe<Card>;
  cost: Money;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  paymentLink: PaymentLink;
  paymentMode: OrderPaymentMode;
  phoneNumber?: Maybe<PhoneNumber>;
  reference: Scalars['String']['output'];
  state: OrderState;
};

export type OrderCursor = {
  __typename?: 'OrderCursor';
  edges: Array<OrderEdge>;
  pageInfo: PageInfo;
};

export type OrderCursorInput = {
  cursor: CursorInput;
  filter?: InputMaybe<OrderFilter>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['ID']['output'];
  node: Order;
};

export type OrderFilter = {
  paymentLinkId?: InputMaybe<Scalars['ID']['input']>;
  states?: InputMaybe<Array<OrderState>>;
};

export type OrderInput = {
  card?: InputMaybe<CardInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  paymentLinkId: Scalars['ID']['input'];
  paymentMode: OrderPaymentMode;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
};

/** OrderPaymentMode to identity method of payment for an order */
export enum OrderPaymentMode {
  Card = 'CARD',
  Mpesa = 'MPESA'
}

/** OrderState to identity the state of an order */
export enum OrderState {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['ID']['output'];
  entries: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPrevPage: Scalars['Boolean']['output'];
  pages: Scalars['Int']['output'];
  startCursor: Scalars['ID']['output'];
};

export type PaymentLink = {
  __typename?: 'PaymentLink';
  active: Scalars['Boolean']['output'];
  business: Business;
  cost: Money;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['Int']['output']>;
  /**
   * Unique reference for the payment link to be used as a shorter more user friendly string over id's UUID version
   * this will also be used as the payment link URL identifier
   */
  reference: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PaymentLinkCursor = {
  __typename?: 'PaymentLinkCursor';
  edges: Array<PaymentLinkEdge>;
  pageInfo: PageInfo;
};

export type PaymentLinkCursorInput = {
  cursor: CursorInput;
};

export type PaymentLinkEdge = {
  __typename?: 'PaymentLinkEdge';
  cursor: Scalars['ID']['output'];
  node: PaymentLink;
};

export type PaymentLinkInput = {
  cost: MoneyInput;
  description?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

/** Represents a payout request */
export type Payout = {
  __typename?: 'Payout';
  amount: Money;
  bankAccount: BankAccount;
  business: Business;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: PayoutStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type PayoutConnection = {
  __typename?: 'PayoutConnection';
  edges: Array<PayoutEdge>;
  pageInfo: PageInfo;
};

export type PayoutEdge = {
  __typename?: 'PayoutEdge';
  cursor: Scalars['ID']['output'];
  node: Payout;
};

/** Input for filtering payouts */
export type PayoutFilterInput = {
  cursor: CursorInput;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<PayoutStatus>;
};

export enum PayoutStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  PendingVerification = 'PENDING_VERIFICATION',
  Processing = 'PROCESSING'
}

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  countryCode: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

export type PhoneNumberInput = {
  countryCode: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String']['input'];
};

/** Input for requesting a new payout */
export type RequestPayoutInput = {
  amount: MoneyInput;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Add Order */
  addOrder: Order;
  /** Add PaymentLink */
  addPaymentLink?: Maybe<PaymentLink>;
  /** User login */
  login?: Maybe<Login>;
  /** Logout */
  logout?: Maybe<Scalars['Boolean']['output']>;
  /** Refresh Token */
  refreshToken?: Maybe<Login>;
  /** Request a new payout */
  requestPayout: Payout;
  /** Resend Email Verification. If no email is provided we will use the auth context to figure it out. */
  resendEmailVerification: Scalars['Boolean']['output'];
  /** Resend verification code if expired */
  sendPayoutCode: Scalars['Boolean']['output'];
  /** User registration */
  signup?: Maybe<User>;
  /** Step up Order to get the next credentials for card iframe */
  stepUpOrder: Order;
  submitBusinessOnboarding: Scalars['Boolean']['output'];
  updateBusinessOnboarding: BusinessOnboarding;
  updatePaymentLink: PaymentLink;
  uploadMedia: Media;
  /** Verify Email with the token user received */
  verifyEmail: Scalars['Boolean']['output'];
  /** Verify the SMS code for a payout */
  verifyPayoutCode: Payout;
};


export type RootMutationTypeAddOrderArgs = {
  input: OrderInput;
};


export type RootMutationTypeAddPaymentLinkArgs = {
  input: PaymentLinkInput;
};


export type RootMutationTypeLoginArgs = {
  input?: InputMaybe<LoginInput>;
};


export type RootMutationTypeLogoutArgs = {
  input?: InputMaybe<LogoutInput>;
};


export type RootMutationTypeRefreshTokenArgs = {
  input?: InputMaybe<RefreshTokenInput>;
};


export type RootMutationTypeRequestPayoutArgs = {
  input: RequestPayoutInput;
};


export type RootMutationTypeResendEmailVerificationArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeSendPayoutCodeArgs = {
  payoutId: Scalars['ID']['input'];
};


export type RootMutationTypeSignupArgs = {
  input?: InputMaybe<SignupInput>;
};


export type RootMutationTypeStepUpOrderArgs = {
  input: OrderInput;
};


export type RootMutationTypeUpdateBusinessOnboardingArgs = {
  input: BusinessOnboardingInput;
};


export type RootMutationTypeUpdatePaymentLinkArgs = {
  input: UpdatePaymentLinkInput;
};


export type RootMutationTypeUploadMediaArgs = {
  file: Scalars['Upload']['input'];
};


export type RootMutationTypeVerifyEmailArgs = {
  token: Scalars['String']['input'];
};


export type RootMutationTypeVerifyPayoutCodeArgs = {
  input: VerifyCodeInput;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  banks: Array<Bank>;
  /** Returns the business associated with the current user, if it exists */
  business?: Maybe<Business>;
  businessCategories: Array<BusinessCategory>;
  businessOnboarding?: Maybe<BusinessOnboarding>;
  /** This is needed to import the error type into the Graphql docs [for Absinthee] */
  error?: Maybe<ValidationError>;
  /** Get current user */
  me?: Maybe<User>;
  /** Get a single order via either id or reference */
  order: Order;
  /** Get all orders belonging to a business with cursor based pagination */
  orders: OrderCursor;
  /** Get a single payment link via either id or reference */
  paymentLink: PaymentLink;
  /** Get all payment links belonging to a business with cursor based pagination */
  paymentLinks: PaymentLinkCursor;
  /** Get a single payout by ID */
  payout: Payout;
  /** Get all payouts for the authenticated user's business */
  payouts: PayoutConnection;
  /** Get all users with cursor based pagination */
  users: UserCursor;
};


export type RootQueryTypeOrderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeOrdersArgs = {
  input: OrderCursorInput;
};


export type RootQueryTypePaymentLinkArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypePaymentLinksArgs = {
  input: PaymentLinkCursorInput;
};


export type RootQueryTypePayoutArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypePayoutsArgs = {
  input: PayoutFilterInput;
};


export type RootQueryTypeUsersArgs = {
  input: UsersInput;
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  /** Same as order query but with real time updates */
  order: Order;
  /** Same as orders query but with real time updates */
  orders: OrderCursor;
  /** Get a single payment link via either id or reference */
  paymentLink: PaymentLink;
};


export type RootSubscriptionTypeOrderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};


export type RootSubscriptionTypeOrdersArgs = {
  input: OrderCursorInput;
};


export type RootSubscriptionTypePaymentLinkArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type SignupInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: PhoneNumberInput;
};

export type UpdatePaymentLinkInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  cost?: InputMaybe<MoneyInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Image>;
  phone?: Maybe<PhoneNumber>;
  roles: Array<UserRole>;
  status: UserStatus;
};

export type UserCursor = {
  __typename?: 'UserCursor';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['ID']['output'];
  node: User;
};

export enum UserRole {
  Admin = 'ADMIN',
  Merchant = 'MERCHANT'
}

export enum UserStatus {
  Active = 'ACTIVE',
  Created = 'CREATED',
  Deactivated = 'DEACTIVATED'
}

export type UsersInput = {
  cursor: CursorInput;
};

export type ValidationError = Error & {
  __typename?: 'ValidationError';
  key: Scalars['String']['output'];
  /** Should give details sometimes. Example: a @cashia tag is already taken. */
  message?: Maybe<Scalars['String']['output']>;
  type: ErrorTypes;
};

/** Represents a verification code */
export type VerificationCode = {
  __typename?: 'VerificationCode';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: VerificationCodeStatus;
};

export enum VerificationCodeStatus {
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Verified = 'VERIFIED'
}

/** Type of identity verification document */
export enum VerificationIdType {
  AlienCard = 'ALIEN_CARD',
  NationalId = 'NATIONAL_ID',
  Passport = 'PASSPORT'
}

/** Input for verifying a code */
export type VerifyCodeInput = {
  code: Scalars['String']['input'];
  payoutId: Scalars['ID']['input'];
};

export type PhoneNumberFieldsFragment = { __typename?: 'PhoneNumber', number: string, countryCode: string };

export type UserFieldsFragment = { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt?: any | null, status: UserStatus, emailVerifiedAt?: any | null, roles: Array<UserRole>, phone?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null };

export type UserEdgeFieldsFragment = { __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt?: any | null, status: UserStatus, emailVerifiedAt?: any | null, roles: Array<UserRole>, phone?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } };

export type UserCursorFieldsFragment = { __typename?: 'UserCursor', edges: Array<{ __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt?: any | null, status: UserStatus, emailVerifiedAt?: any | null, roles: Array<UserRole>, phone?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, startCursor: string, endCursor: string, entries: number, pages: number } };

export type RefreshTokenMutationVariables = Exact<{
  input: RefreshTokenInput;
}>;


export type RefreshTokenMutation = { __typename?: 'RootMutationType', refreshToken?: { __typename?: 'Login', accessToken: string, refreshToken: string } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'RootQueryType', me?: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt?: any | null, status: UserStatus, emailVerifiedAt?: any | null, roles: Array<UserRole>, phone?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } | null };

export type GetUsersQueryVariables = Exact<{
  input: UsersInput;
}>;


export type GetUsersQuery = { __typename?: 'RootQueryType', users: { __typename?: 'UserCursor', edges: Array<{ __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, createdAt?: any | null, status: UserStatus, emailVerifiedAt?: any | null, roles: Array<UserRole>, phone?: { __typename?: 'PhoneNumber', number: string, countryCode: string } | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, startCursor: string, endCursor: string, entries: number, pages: number } } };

export const PhoneNumberFieldsFragmentDoc = gql`
    fragment phoneNumberFields on PhoneNumber {
  number
  countryCode
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id
  firstName
  lastName
  email
  phone {
    ...phoneNumberFields
  }
  createdAt
  status
  emailVerifiedAt
  roles
}
    ${PhoneNumberFieldsFragmentDoc}`;
export const UserEdgeFieldsFragmentDoc = gql`
    fragment userEdgeFields on UserEdge {
  cursor
  node {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const UserCursorFieldsFragmentDoc = gql`
    fragment userCursorFields on UserCursor {
  edges {
    ...userEdgeFields
  }
  pageInfo {
    hasNextPage
    hasPrevPage
    startCursor
    endCursor
    entries
    pages
  }
}
    ${UserEdgeFieldsFragmentDoc}`;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($input: RefreshTokenInput!) {
  refreshToken(input: $input) {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($input: UsersInput!) {
  users(input: $input) {
    ...userCursorFields
  }
}
    ${UserCursorFieldsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables> & ({ variables: GetUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;


/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = ResolversObject<{
  Error: ( ValidationError );
}>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  Bank: ResolverTypeWrapper<Bank>;
  BankAccount: ResolverTypeWrapper<BankAccount>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Business: ResolverTypeWrapper<Business>;
  BusinessCategory: ResolverTypeWrapper<BusinessCategory>;
  BusinessOnboarding: ResolverTypeWrapper<BusinessOnboarding>;
  BusinessOnboardingBankAccountInput: BusinessOnboardingBankAccountInput;
  BusinessOnboardingInput: BusinessOnboardingInput;
  BusinessOnboardingOwnerInput: BusinessOnboardingOwnerInput;
  BusinessOnboardingSocialMediaInput: BusinessOnboardingSocialMediaInput;
  BusinessOwner: ResolverTypeWrapper<BusinessOwner>;
  BusinessSocialMedia: ResolverTypeWrapper<BusinessSocialMedia>;
  Card: ResolverTypeWrapper<Card>;
  CardFlowData: ResolverTypeWrapper<CardFlowData>;
  CardFlowDataInput: CardFlowDataInput;
  CardInput: CardInput;
  CardIssuer: CardIssuer;
  CurrencyCode: CurrencyCode;
  CursorInput: CursorInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  Error: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Error']>;
  ErrorTypes: ErrorTypes;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GeoCoordinates: ResolverTypeWrapper<GeoCoordinates>;
  GeoCoordinatesInput: GeoCoordinatesInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Image: ResolverTypeWrapper<Image>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Location: ResolverTypeWrapper<Location>;
  Login: ResolverTypeWrapper<Login>;
  LoginInput: LoginInput;
  LogoutInput: LogoutInput;
  Media: ResolverTypeWrapper<Media>;
  Money: ResolverTypeWrapper<Money>;
  MoneyInput: MoneyInput;
  Order: ResolverTypeWrapper<Order>;
  OrderCursor: ResolverTypeWrapper<OrderCursor>;
  OrderCursorInput: OrderCursorInput;
  OrderEdge: ResolverTypeWrapper<OrderEdge>;
  OrderFilter: OrderFilter;
  OrderInput: OrderInput;
  OrderPaymentMode: OrderPaymentMode;
  OrderState: OrderState;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaymentLink: ResolverTypeWrapper<PaymentLink>;
  PaymentLinkCursor: ResolverTypeWrapper<PaymentLinkCursor>;
  PaymentLinkCursorInput: PaymentLinkCursorInput;
  PaymentLinkEdge: ResolverTypeWrapper<PaymentLinkEdge>;
  PaymentLinkInput: PaymentLinkInput;
  Payout: ResolverTypeWrapper<Payout>;
  PayoutConnection: ResolverTypeWrapper<PayoutConnection>;
  PayoutEdge: ResolverTypeWrapper<PayoutEdge>;
  PayoutFilterInput: PayoutFilterInput;
  PayoutStatus: PayoutStatus;
  PhoneNumber: ResolverTypeWrapper<PhoneNumber>;
  PhoneNumberInput: PhoneNumberInput;
  RefreshTokenInput: RefreshTokenInput;
  RequestPayoutInput: RequestPayoutInput;
  RootMutationType: ResolverTypeWrapper<{}>;
  RootQueryType: ResolverTypeWrapper<{}>;
  RootSubscriptionType: ResolverTypeWrapper<{}>;
  SignupInput: SignupInput;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  TimeInHm: ResolverTypeWrapper<Scalars['TimeInHm']['output']>;
  UpdatePaymentLinkInput: UpdatePaymentLinkInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']['output']>;
  User: ResolverTypeWrapper<User>;
  UserCursor: ResolverTypeWrapper<UserCursor>;
  UserEdge: ResolverTypeWrapper<UserEdge>;
  UserRole: UserRole;
  UserStatus: UserStatus;
  UsersInput: UsersInput;
  ValidationError: ResolverTypeWrapper<ValidationError>;
  VerificationCode: ResolverTypeWrapper<VerificationCode>;
  VerificationCodeStatus: VerificationCodeStatus;
  VerificationIdType: VerificationIdType;
  VerifyCodeInput: VerifyCodeInput;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Address: Address;
  AddressInput: AddressInput;
  Bank: Bank;
  BankAccount: BankAccount;
  Boolean: Scalars['Boolean']['output'];
  Business: Business;
  BusinessCategory: BusinessCategory;
  BusinessOnboarding: BusinessOnboarding;
  BusinessOnboardingBankAccountInput: BusinessOnboardingBankAccountInput;
  BusinessOnboardingInput: BusinessOnboardingInput;
  BusinessOnboardingOwnerInput: BusinessOnboardingOwnerInput;
  BusinessOnboardingSocialMediaInput: BusinessOnboardingSocialMediaInput;
  BusinessOwner: BusinessOwner;
  BusinessSocialMedia: BusinessSocialMedia;
  Card: Card;
  CardFlowData: CardFlowData;
  CardFlowDataInput: CardFlowDataInput;
  CardInput: CardInput;
  CursorInput: CursorInput;
  DateTime: Scalars['DateTime']['output'];
  Error: ResolversInterfaceTypes<ResolversParentTypes>['Error'];
  Float: Scalars['Float']['output'];
  GeoCoordinates: GeoCoordinates;
  GeoCoordinatesInput: GeoCoordinatesInput;
  ID: Scalars['ID']['output'];
  Image: Image;
  Int: Scalars['Int']['output'];
  Location: Location;
  Login: Login;
  LoginInput: LoginInput;
  LogoutInput: LogoutInput;
  Media: Media;
  Money: Money;
  MoneyInput: MoneyInput;
  Order: Order;
  OrderCursor: OrderCursor;
  OrderCursorInput: OrderCursorInput;
  OrderEdge: OrderEdge;
  OrderFilter: OrderFilter;
  OrderInput: OrderInput;
  PageInfo: PageInfo;
  PaymentLink: PaymentLink;
  PaymentLinkCursor: PaymentLinkCursor;
  PaymentLinkCursorInput: PaymentLinkCursorInput;
  PaymentLinkEdge: PaymentLinkEdge;
  PaymentLinkInput: PaymentLinkInput;
  Payout: Payout;
  PayoutConnection: PayoutConnection;
  PayoutEdge: PayoutEdge;
  PayoutFilterInput: PayoutFilterInput;
  PhoneNumber: PhoneNumber;
  PhoneNumberInput: PhoneNumberInput;
  RefreshTokenInput: RefreshTokenInput;
  RequestPayoutInput: RequestPayoutInput;
  RootMutationType: {};
  RootQueryType: {};
  RootSubscriptionType: {};
  SignupInput: SignupInput;
  String: Scalars['String']['output'];
  TimeInHm: Scalars['TimeInHm']['output'];
  UpdatePaymentLinkInput: UpdatePaymentLinkInput;
  Upload: Scalars['Upload']['output'];
  User: User;
  UserCursor: UserCursor;
  UserEdge: UserEdge;
  UsersInput: UsersInput;
  ValidationError: ValidationError;
  VerificationCode: VerificationCode;
  VerifyCodeInput: VerifyCodeInput;
}>;

export type AuthenticatedDirectiveArgs = {
  roles?: Maybe<Array<UserRole>>;
};

export type AuthenticatedDirectiveResolver<Result, Parent, ContextType = any, Args = AuthenticatedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = ResolversObject<{
  buildingName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bank'] = ResolversParentTypes['Bank']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankAccount'] = ResolversParentTypes['BankAccount']> = ResolversObject<{
  accountHolder?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bank?: Resolver<ResolversTypes['Bank'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessResolvers<ContextType = any, ParentType extends ResolversParentTypes['Business'] = ResolversParentTypes['Business']> = ResolversObject<{
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  bankAccount?: Resolver<ResolversTypes['BankAccount'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['BusinessCategory'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['BusinessOwner'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['PhoneNumber'], ParentType, ContextType>;
  socialMedia?: Resolver<ResolversTypes['BusinessSocialMedia'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessCategory'] = ResolversParentTypes['BusinessCategory']> = ResolversObject<{
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessOnboardingResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessOnboarding'] = ResolversParentTypes['BusinessOnboarding']> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  bankAccountBank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType>;
  bankAccountHolder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankAccountNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['BusinessCategory']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  ownerFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerIdPhotos?: Resolver<Maybe<Array<ResolversTypes['Media']>>, ParentType, ContextType>;
  ownerIdType?: Resolver<Maybe<ResolversTypes['VerificationIdType']>, ParentType, ContextType>;
  ownerLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  socialMedia?: Resolver<Maybe<ResolversTypes['BusinessSocialMedia']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessOwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessOwner'] = ResolversParentTypes['BusinessOwner']> = ResolversObject<{
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idPhotos?: Resolver<Array<ResolversTypes['Media']>, ParentType, ContextType>;
  idType?: Resolver<ResolversTypes['VerificationIdType'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BusinessSocialMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessSocialMedia'] = ResolversParentTypes['BusinessSocialMedia']> = ResolversObject<{
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  snapchat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card']> = ResolversObject<{
  flowData?: Resolver<Maybe<ResolversTypes['CardFlowData']>, ParentType, ContextType>;
  issuer?: Resolver<Maybe<ResolversTypes['CardIssuer']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CardFlowDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CardFlowData'] = ResolversParentTypes['CardFlowData']> = ResolversObject<{
  actionUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type ErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ValidationError', ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ErrorTypes'], ParentType, ContextType>;
}>;

export type GeoCoordinatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoCoordinates'] = ResolversParentTypes['GeoCoordinates']> = ResolversObject<{
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  long?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  large?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  medium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  small?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = ResolversObject<{
  coordinates?: Resolver<ResolversTypes['GeoCoordinates'], ParentType, ContextType>;
  formattedAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoginResolvers<ContextType = any, ParentType extends ResolversParentTypes['Login'] = ResolversParentTypes['Login']> = ResolversObject<{
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media']> = ResolversObject<{
  contentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MoneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money']> = ResolversObject<{
  amountInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['CurrencyCode'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = ResolversObject<{
  card?: Resolver<Maybe<ResolversTypes['Card']>, ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentLink?: Resolver<ResolversTypes['PaymentLink'], ParentType, ContextType>;
  paymentMode?: Resolver<ResolversTypes['OrderPaymentMode'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['OrderState'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderCursorResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCursor'] = ResolversParentTypes['OrderCursor']> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['OrderEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderEdge'] = ResolversParentTypes['OrderEdge']> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Order'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = ResolversObject<{
  endCursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  entries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPrevPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startCursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentLink'] = ResolversParentTypes['PaymentLink']> = ResolversObject<{
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  business?: Resolver<ResolversTypes['Business'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentLinkCursorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentLinkCursor'] = ResolversParentTypes['PaymentLinkCursor']> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['PaymentLinkEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentLinkEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentLinkEdge'] = ResolversParentTypes['PaymentLinkEdge']> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PaymentLink'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PayoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payout'] = ResolversParentTypes['Payout']> = ResolversObject<{
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  bankAccount?: Resolver<ResolversTypes['BankAccount'], ParentType, ContextType>;
  business?: Resolver<ResolversTypes['Business'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PayoutStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PayoutConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayoutConnection'] = ResolversParentTypes['PayoutConnection']> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['PayoutEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PayoutEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayoutEdge'] = ResolversParentTypes['PayoutEdge']> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Payout'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhoneNumberResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhoneNumber'] = ResolversParentTypes['PhoneNumber']> = ResolversObject<{
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RootMutationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootMutationType'] = ResolversParentTypes['RootMutationType']> = ResolversObject<{
  addOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<RootMutationTypeAddOrderArgs, 'input'>>;
  addPaymentLink?: Resolver<Maybe<ResolversTypes['PaymentLink']>, ParentType, ContextType, RequireFields<RootMutationTypeAddPaymentLinkArgs, 'input'>>;
  login?: Resolver<Maybe<ResolversTypes['Login']>, ParentType, ContextType, Partial<RootMutationTypeLoginArgs>>;
  logout?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<RootMutationTypeLogoutArgs>>;
  refreshToken?: Resolver<Maybe<ResolversTypes['Login']>, ParentType, ContextType, Partial<RootMutationTypeRefreshTokenArgs>>;
  requestPayout?: Resolver<ResolversTypes['Payout'], ParentType, ContextType, RequireFields<RootMutationTypeRequestPayoutArgs, 'input'>>;
  resendEmailVerification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<RootMutationTypeResendEmailVerificationArgs>>;
  sendPayoutCode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<RootMutationTypeSendPayoutCodeArgs, 'payoutId'>>;
  signup?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<RootMutationTypeSignupArgs>>;
  stepUpOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<RootMutationTypeStepUpOrderArgs, 'input'>>;
  submitBusinessOnboarding?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateBusinessOnboarding?: Resolver<ResolversTypes['BusinessOnboarding'], ParentType, ContextType, RequireFields<RootMutationTypeUpdateBusinessOnboardingArgs, 'input'>>;
  updatePaymentLink?: Resolver<ResolversTypes['PaymentLink'], ParentType, ContextType, RequireFields<RootMutationTypeUpdatePaymentLinkArgs, 'input'>>;
  uploadMedia?: Resolver<ResolversTypes['Media'], ParentType, ContextType, RequireFields<RootMutationTypeUploadMediaArgs, 'file'>>;
  verifyEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<RootMutationTypeVerifyEmailArgs, 'token'>>;
  verifyPayoutCode?: Resolver<ResolversTypes['Payout'], ParentType, ContextType, RequireFields<RootMutationTypeVerifyPayoutCodeArgs, 'input'>>;
}>;

export type RootQueryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootQueryType'] = ResolversParentTypes['RootQueryType']> = ResolversObject<{
  banks?: Resolver<Array<ResolversTypes['Bank']>, ParentType, ContextType>;
  business?: Resolver<Maybe<ResolversTypes['Business']>, ParentType, ContextType>;
  businessCategories?: Resolver<Array<ResolversTypes['BusinessCategory']>, ParentType, ContextType>;
  businessOnboarding?: Resolver<Maybe<ResolversTypes['BusinessOnboarding']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['ValidationError']>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Order'], ParentType, ContextType, Partial<RootQueryTypeOrderArgs>>;
  orders?: Resolver<ResolversTypes['OrderCursor'], ParentType, ContextType, RequireFields<RootQueryTypeOrdersArgs, 'input'>>;
  paymentLink?: Resolver<ResolversTypes['PaymentLink'], ParentType, ContextType, Partial<RootQueryTypePaymentLinkArgs>>;
  paymentLinks?: Resolver<ResolversTypes['PaymentLinkCursor'], ParentType, ContextType, RequireFields<RootQueryTypePaymentLinksArgs, 'input'>>;
  payout?: Resolver<ResolversTypes['Payout'], ParentType, ContextType, RequireFields<RootQueryTypePayoutArgs, 'id'>>;
  payouts?: Resolver<ResolversTypes['PayoutConnection'], ParentType, ContextType, RequireFields<RootQueryTypePayoutsArgs, 'input'>>;
  users?: Resolver<ResolversTypes['UserCursor'], ParentType, ContextType, RequireFields<RootQueryTypeUsersArgs, 'input'>>;
}>;

export type RootSubscriptionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootSubscriptionType'] = ResolversParentTypes['RootSubscriptionType']> = ResolversObject<{
  order?: SubscriptionResolver<ResolversTypes['Order'], "order", ParentType, ContextType, Partial<RootSubscriptionTypeOrderArgs>>;
  orders?: SubscriptionResolver<ResolversTypes['OrderCursor'], "orders", ParentType, ContextType, RequireFields<RootSubscriptionTypeOrdersArgs, 'input'>>;
  paymentLink?: SubscriptionResolver<ResolversTypes['PaymentLink'], "paymentLink", ParentType, ContextType, Partial<RootSubscriptionTypePaymentLinkArgs>>;
}>;

export interface TimeInHmScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['TimeInHm'], any> {
  name: 'TimeInHm';
}

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailVerifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['UserRole']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserCursorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCursor'] = ResolversParentTypes['UserCursor']> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['UserEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserEdge'] = ResolversParentTypes['UserEdge']> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValidationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidationError'] = ResolversParentTypes['ValidationError']> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ErrorTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VerificationCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerificationCode'] = ResolversParentTypes['VerificationCode']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['VerificationCodeStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Address?: AddressResolvers<ContextType>;
  Bank?: BankResolvers<ContextType>;
  BankAccount?: BankAccountResolvers<ContextType>;
  Business?: BusinessResolvers<ContextType>;
  BusinessCategory?: BusinessCategoryResolvers<ContextType>;
  BusinessOnboarding?: BusinessOnboardingResolvers<ContextType>;
  BusinessOwner?: BusinessOwnerResolvers<ContextType>;
  BusinessSocialMedia?: BusinessSocialMediaResolvers<ContextType>;
  Card?: CardResolvers<ContextType>;
  CardFlowData?: CardFlowDataResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Error?: ErrorResolvers<ContextType>;
  GeoCoordinates?: GeoCoordinatesResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  Login?: LoginResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  Money?: MoneyResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderCursor?: OrderCursorResolvers<ContextType>;
  OrderEdge?: OrderEdgeResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaymentLink?: PaymentLinkResolvers<ContextType>;
  PaymentLinkCursor?: PaymentLinkCursorResolvers<ContextType>;
  PaymentLinkEdge?: PaymentLinkEdgeResolvers<ContextType>;
  Payout?: PayoutResolvers<ContextType>;
  PayoutConnection?: PayoutConnectionResolvers<ContextType>;
  PayoutEdge?: PayoutEdgeResolvers<ContextType>;
  PhoneNumber?: PhoneNumberResolvers<ContextType>;
  RootMutationType?: RootMutationTypeResolvers<ContextType>;
  RootQueryType?: RootQueryTypeResolvers<ContextType>;
  RootSubscriptionType?: RootSubscriptionTypeResolvers<ContextType>;
  TimeInHm?: GraphQLScalarType;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserCursor?: UserCursorResolvers<ContextType>;
  UserEdge?: UserEdgeResolvers<ContextType>;
  ValidationError?: ValidationErrorResolvers<ContextType>;
  VerificationCode?: VerificationCodeResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = any> = ResolversObject<{
  authenticated?: AuthenticatedDirectiveResolver<any, any, ContextType>;
}>;
