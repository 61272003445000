import {Toaster} from 'react-hot-toast';
import {Route, Routes} from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';
import AccountsPage from '../pages/Accounts/Accounts';
import Login from '../pages/Login';
import ComingSoon from '../pages/empty';

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          <Route index path="accounts" element={<AccountsPage />} />
          <Route path="accounts/account-details/:id" element={<ComingSoon />} />
          <Route path="business-profiles" element={<ComingSoon />} />
          <Route path="users" element={<ComingSoon />} />
          <Route path="payments" element={<ComingSoon />} />
        </Route>
      </Routes>
      <Toaster />
    </>
  );
};
export default AppRoutes;
