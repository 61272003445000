interface Options {
  withWeekday?: boolean;
  withTime?: boolean;
  withDate?: boolean;
  withMonth?: boolean;
  withShortMonth?: boolean;
  withYear?: boolean;
  hour12?: boolean;
  locales?: string | string[];
}

type FormatDate = (date: Date, options?: Options) => string;

const formatDate: FormatDate = (
  date,
  options = {
    withDate: true,
    withTime: true,
    withShortMonth: false,
    withYear: true,
    hour12: true,
    locales: 'en-KE',
  }
) => {
  const d = new Date(date);

  const day = d.getDate();
  const month = options.withShortMonth
    ? d.toLocaleString('en-KE', {month: 'short'})
    : d.toLocaleString('en-KE', {month: 'long'});
  const year = d.getFullYear();

  const time = d
    .toLocaleString('en-KE', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    .toUpperCase();

  return `${day} ${month}, ${year} - ${time}`;
};

export default formatDate;
