import {PropsWithChildren, useCallback, useMemo} from 'react';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';

import Logout from '../assets/icons/logout.svg';
import MerchantActive from '../assets/icons/merchant-active.svg';
import Merchant from '../assets/icons/merchant.svg';
import MoneyChangeActive from '../assets/icons/money-change-active.svg';
import MoneyChange from '../assets/icons/money-change.svg';
import ProfileActiveIcon from '../assets/icons/profile-active.svg';
import ProfileIcon from '../assets/icons/profile.svg';
import {ReactComponent as RoleIcon} from '../assets/icons/role-icon.svg';
import UsersActive from '../assets/icons/users-active.svg';
import Users from '../assets/icons/users.svg';
import {useAuth} from '../utils/auth';
import {cn} from '../utils/reusablefunctions';
import {useUserAuth} from '../utils/user';

const MainLayout = () => {
  const {logout} = useAuth();
  const navigate = useNavigate();
  const {user} = useUserAuth();
  const {pathname} = useLocation();

  const Menu = useMemo(
    () => [
      {
        title: 'Accounts',
        icon: Merchant,
        activeIcon: MerchantActive,
        link: '/accounts',
      },
      {
        title: 'Business Profiles',
        icon: ProfileIcon,
        activeIcon: ProfileActiveIcon,
        link: '/business-profiles',
      },
      {
        title: 'Users',
        icon: Users,
        activeIcon: UsersActive,
        link: '/users',
        match: 'user',
      },
      {
        title: 'Payments',
        icon: MoneyChange,
        activeIcon: MoneyChangeActive,
        link: '/payments',
        match: 'payment',
      },
    ],
    []
  );

  const initials = useMemo(
    () =>
      `${user?.firstName?.[0] || ''}${user?.lastName?.[0] || ''}`.toUpperCase(),
    [user]
  );

  const isActive = useCallback(
    (path: string) => pathname.includes(path),
    [pathname]
  );

  const currentMenuTitle = useMemo(() => {
    return Menu.find((item) => isActive(item.match || item.link))?.title || '';
  }, [Menu, isActive]);

  return (
    <RequireAuth>
      <div className="flex h-screen w-screen overflow-hidden">
        <div className="relative flex h-screen w-80 flex-col gap-8 bg-offWhite p-5 pt-10">
          <div className="flex flex-col">
            <div className="flex gap-2">
              <div className="relative inline-flex h-[45px] w-[45px] items-center justify-center rounded-full bg-mediumPurple text-xl font-bold text-white">
                {initials}
                <span className="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-green-500" />
              </div>
              <div className="flex flex-col">
                <h2 className="whitespace-nowrap text-black duration-300">
                  {user?.firstName} {user?.lastName}
                </h2>
                <p className="whitespace-nowrap text-sm text-foggy duration-300">
                  online
                </p>
              </div>
            </div>
          </div>
          <div className="flex h-[55px] w-full items-center gap-2 rounded-[10px] border-[#D6D4DE] bg-purpleGrey px-2">
            <RoleIcon />
            <div className="flex flex-col">
              <p className="text-[16px] font-[500]">Role:</p>
              <p className="text-[12px] font-[600] text-greyscale">
                {user?.roles}
              </p>
            </div>
          </div>
          <ul className="flex w-full flex-1 flex-col justify-between overflow-y-auto">
            <div>
              {Menu.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => navigate(menu.link)}
                  className={cn(
                    `mb-4 flex h-[48px] cursor-pointer items-center gap-x-4 rounded-lg p-2 text-sm text-foggy active:text-mediumPurple`,
                    {
                      'bg-lightPurple font-semibold text-darkPurple': isActive(
                        menu.match || menu.link
                      ),
                    }
                  )}>
                  <img
                    src={
                      isActive(menu.match || menu.link)
                        ? menu.activeIcon
                        : menu.icon
                    }
                    className="block h-6 w-6"
                  />
                  <span className="flex-1 whitespace-nowrap break-keep text-base">
                    {menu.title}
                  </span>
                </li>
              ))}
            </div>
            <li
              onClick={logout}
              className="mt-2 flex cursor-pointer items-center gap-x-4 rounded-md p-2 text-foggy">
              <img src={Logout} />
              <span className="flex-1 whitespace-nowrap break-keep text-smoothRed">
                Log out
              </span>
            </li>
          </ul>
        </div>

        <div className="w-full bg-white">
          <nav className="h-[100px] sm:px-4 md:px-8 flex items-center border-b-2 bg-white z-10">
            <div className="text-2xl font-semibold text-[#1D2939]">
              {currentMenuTitle}
            </div>
          </nav>
          <div className="flex-1 h-[calc(100svh-80px)] overflow-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </RequireAuth>
  );
};

const RequireAuth = ({children}: PropsWithChildren) => {
  const {isAuthenticated} = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{from: location}} replace />;
  }
  return <>{children}</>;
};

export default MainLayout;
